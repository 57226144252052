import { FC, useEffect } from 'react'
import Paragraph from '../../components/Paragraph'
import Heading from '../../components/Heading'
import HeadingIntro from '../../components/HeadingIntro'
import { Button } from '../../components/Button'
import classNames from 'classnames'
import { useModal } from '../modal/useModal'
import { ModalContent } from '../modal/modalContent.enum'
import { useAppSelector } from '../../store/store'
import { selectMyUser } from '../users/usersSlice'
import { DownloadButton } from '../download/DownloadButton'
import { useRouter } from 'next/router'

const Hero: FC = () => {
  const { showModalWith } = useModal()
  const { data: user } = useAppSelector(selectMyUser)
  const router = useRouter()

  useEffect(() => {
    const isForgotPassword = router.query['forgot-password'] === 'true'
    if (isForgotPassword) {
      showModalWith({ name: ModalContent.ForgotPasswordForm })
    }
  }, [router])

  return (
    <div
      className={classNames(
        'bg-alphaHero bg-left-top bg-cover flex items-center justify-start w-full my-5 h-[25.875rem] rounded-xl',
        '2xl:h-[40rem]',
        'sm:items-end',
        'mobile:h-[40rem] mobile:bg-center'
      )}
    >
      <div
        className={classNames(
          'rounded-xl backdrop-blur-md bg-mirrorGlass mx-7 my-6',
          'lg:p-[2.25rem] lg:max-w-[28rem]',
          'lt-lg:w-full'
        )}
      >
        <div
          className={classNames(
            'flex h-full gap-8',
            'lg:flex-col',
            'lt-lg:items-center lt-lg:flex-wrap lt-lg:p-7 lt-lg:justify-between',
            'mobile:gap-2'
          )}
        >
          <div className="flex flex-col flex-wrap lg:gap-1">
            <HeadingIntro className="text-greenMirror py-2 mb-2 sm:pb-0">
              Open Alpha
            </HeadingIntro>

            <Heading size="2"> Infinity Release </Heading>

            <Paragraph className="text-left py-0 pt-2">
              The Mirror is now open-source:
            </Paragraph>
            <Paragraph className="text-left py-0">
              Infinite possibilities
            </Paragraph>
          </div>

          <div className="flex items-center gap-3">
            {!user && (
              <Button
                className="text-sm font-semibold h-full w-[9rem]"
                onClick={() =>
                  showModalWith({ name: ModalContent.SignInModalContent })
                }
              >
                Sign In
              </Button>
            )}
            <DownloadButton />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
