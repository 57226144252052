import React, { FC } from 'react'
import InnerContainerPage from 'components/InnerContainerPage'
import { useAppSelector } from '../store/store'
import { selectMyUser } from '../features/users/usersSlice'

import Head from 'next/head'
import ClosedAlphaLanding from '../features/alpha/ClosedAlphaLanding'
import Heading from '../components/Heading'
import HeaderSection from '../components/HeaderSection'

const Home: FC = () => {
  const { data: user } = useAppSelector(selectMyUser)

  return (
    <>
      <Head>
        <title>In The Mirror</title>
        <meta
          property="description"
          content="Game Development Platform: The Ultimate Sandbox"
        />
        <meta property="og:title" content="In The Mirror" />
        <meta
          property="og:description"
          content="Game Development Platform: The Ultimate Sandbox"
        />
        <meta property="og:url" content="https://in.themirror.space/" />
        <meta
          property="og:image"
          content="https://in.themirror.space/images/mirror-itch-cover.jpg"
        />
        {/* twitter specific open graph tags */}
        <meta
          property="twitter:image"
          content="https://in.themirror.space/images/mirror-itch-cover.jpg"
        />
        <meta
          property="twitter:card"
          content="https://in.themirror.space/images/mirror-itch-cover.jpg"
        />
        <meta property="twitter:title" content="In The Mirror" />
        <meta
          property="twitter:description"
          content="Game Development Platform: The Ultimate Sandbox"
        />
      </Head>

      <InnerContainerPage>
        <HeaderSection>
          {user && <Heading size="2">Welcome, {user.displayName}!</Heading>}
        </HeaderSection>
        <ClosedAlphaLanding />
      </InnerContainerPage>
    </>
  )
}

Home['Layout'] = 'User'

export default Home
