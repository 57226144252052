import { FC, ReactNode } from 'react'

type Props = {
  children: ReactNode
  className?: string
}

const HeadingIntro: FC<Props> = ({ children, className }) => {
  return (
    <p className={`${className} font-bold font-primary text-xs uppercase`}>
      {children}
    </p>
  )
}

export default HeadingIntro
