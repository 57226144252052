import React, { FC, ReactNode } from 'react'
import { useAppSelector } from 'store/store'

type InnerContainerPageProps = {
  children: ReactNode
}

const InnerContainerPage: FC<InnerContainerPageProps> = ({ children }) => {
  const { sideView } = useAppSelector((state) => state.sidebarView)

  return (
    <div
      className={`col-start-2 flex flex-col w-full gap-6 ${
        sideView === 'open' ? 'max-w-[108.8125rem]' : 'max-w-[118.8125rem]'
      }`}
    >
      {children}
    </div>
  )
}

export default InnerContainerPage
