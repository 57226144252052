import { FC, ReactNode } from 'react'
import classNames from 'classnames'

type Props = {
  children: ReactNode
  border?: boolean
}
const HeaderSection: FC<Props> = ({ children, border }) => {
  return (
    <section
      className={classNames(
        'flex w-full justify-between lt-lg:flex-col lt-lg:items-center lt-lg:space-y-3',
        { 'pb-2 border-b-2 border-grayBlueMirror100': border }
      )}
    >
      {children}
    </section>
  )
}

export default HeaderSection
