import React, { FC } from 'react'
import Hero from '../home/Hero'
import HomeVideo from '../home/HomeVideo'

const ClosedAlphaLanding: FC = () => {
  return (
    <>
      <Hero />
      <HomeVideo
        accentText="Free & Open-Source"
        headingText="Open-Sourcing The Mirror"
        description="Freedom to own: The Mirror is now fully open-source as an alternative to Roblox and UEFN/Fortnite built on Godot, allowing you to truly own what you build."
        videoUrl="https://www.youtube.com/embed/sN-drt1Bk4k?si=izbeWm9zDRWHgm13"
      />
      <HomeVideo
        accentText="What is The Mirror?"
        headingText="Alpha Teaser"
        description="One app to build & play: the ultimate sandbox. The Mirror is a next-gen game development platform built on open-source Godot."
        videoUrl="https://www.youtube.com/embed/nkAJ2tjkj_g?si=69ABk90Ce5QUSxnB"
      />
      <HomeVideo
        accentText="Devlog"
        headingText="Devlog #14: Seat Editing with Subnodes"
        description="Discover how we've embedded this new node in our game's hierarchy and learn about the exciting adjustments you can make with control points."
        videoUrl="https://www.youtube.com/embed/3dTXjp3uvOA?si=9B8ylCtlQjvgUxlJ"
      />
      <HomeVideo
        accentText="Devlog"
        headingText="Devlog #13: Heightmap Update"
        description="Witness the breathtaking 4k resolution heightmap as it brings our environment to life with wonderful detail!"
        videoUrl="https://www.youtube.com/embed/slKn4nJhgC4?si=vSoDg9d3uQsSfpZb"
      />
    </>
  )
}

export default ClosedAlphaLanding
