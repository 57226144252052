import { FC } from 'react'
import classNames from 'classnames'
import Heading from '../../components/Heading'
import HeadingIntro from '../../components/HeadingIntro'
import Paragraph from '../../components/Paragraph'

type HomeVideoProps = {
  headingText: string
  description: string
  accentText: string
  videoUrl: string
}

const HomeVideo: FC<HomeVideoProps> = ({
  accentText = 'Welcome',
  headingText = 'Devlog',
  description = '',
  videoUrl = 'https://www.youtube.com/embed/8Whc6qWcfSc'
}) => {
  return (
    <div
      className={classNames(
        'grid grid-cols-[1fr,1fr]',
        'border-t-2 border-orangeMirror bg-bgform rounded-b-xl overflow-hidden ',
        'mobile:grid-cols-1'
      )}
    >
      <div className="relative pt-[56.25%] -my-4">
        <iframe
          src={videoUrl}
          className="w-full h-full absolute top-0 left-0 py-2"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <div className=" flex flex-col lt-lg:p-4 lg:p-[10%] justify-center gap-1">
        <HeadingIntro className="text-orangeMirror">{accentText}</HeadingIntro>
        <Heading size="3">{headingText}</Heading>
        <Paragraph className="text-left">{description}</Paragraph>
      </div>
    </div>
  )
}

export default HomeVideo
